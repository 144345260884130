<template>
    <v-btn
        depressed
        large
        color="accent darken-4"
        class="align-self-md-end mt-2"
        @click="emitAction()"
    >
        {{ $t(`Garden.viewProjectSteps`) }}
        <v-icon color="white" size="21" class="ml-2">
            fa-solid fa-eye
        </v-icon>
    </v-btn>
</template>

<script>
export default {
    name: "ViewStepsBtn",

    methods: {
        emitAction() {
            this.$emit("view-steps");
        },
    },
};
</script>
